<template>
  <div class="modal fade" id="modal-keyword-ranking-chart" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"
              id="modal-add-cardLabel" v-if="keyword">Keyword Ranking - {{ keyword.keyword }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="height: 400px;">
          <canvas id="rank-chart-demo"
                  class="drop-shadow"
                  height="40" width="100%"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      chart: null,
      chartData: null,
      keyword: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-keyword-ranking-chart'))
  },
  methods: {
    show(keyword) {
      this.keyword = keyword;

      this.modal.show();

      this.fetchRankData();
    },
    hide() {
      this.modal.hide();
    },
    fetchRankData() {
      if (this.chart) {
        this.chart.destroy();
        this.chartData = null;
      }

      this.axios.get('/keyword-rank/' + this.keyword.id)
          .then((res) => {
            this.initChart(res.data.data);
          });
    },
    initChart(data) {
      this.chartData = data;
      const ctx = document.getElementById('rank-chart-demo').getContext('2d');
      this.chart = new window.Chart(ctx, {
        type: 'line',
        plugins: [window.ChartDataLabels],
        data: {
          labels:data.labels,
          datasets: [{
            label: 'Rank',
            data: data.data,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: function(value) {
                return '#' + value;
              },
              font: {
                weight: 'bold',
              }
            }
          },
          scales: {
            y: {
              reverse: true,
              ticks: {
                padding: 20,
                stepSize: 1
              }
            },
            x: {
              ticks: {
                padding: 20
              }
            }
          }
        }
      });
    }
  }
}
</script>